import { useEffect, useReducer, useCallback, useMemo, useContext } from "react";
import { initializeApp } from "firebase/app";
import {
  getAuth,
  signOut,
  signInWithPopup,
  onAuthStateChanged,
  GoogleAuthProvider,
  GithubAuthProvider,
  TwitterAuthProvider,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  User,
} from "firebase/auth";
import {
  getFirestore,
  collection,
  doc,
  getDoc,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { FIREBASE_API, FIREBASE_DEV_API } from "src/config-global";
import {
  ActionMapType,
  AuthStateType,
  AuthUserType,
  FirebaseContextType,
} from "src/types/user";
import { createContext } from "react";

const firebaseApp =
  process.env.REACT_APP_PROD == "true"
    ? initializeApp(FIREBASE_API)
    : initializeApp(FIREBASE_DEV_API);

const AUTH = getAuth(firebaseApp);

const DB = getFirestore(firebaseApp);

enum Types {
  INITIAL = "INITIAL",
}

type Payload = {
  [Types.INITIAL]: {
    user: AuthUserType;
  };
};

type Action = ActionMapType<Payload>[keyof ActionMapType<Payload>];

const initialState: AuthStateType = {
  user: null,
  loading: true,
};

const reducer = (state: AuthStateType, action: Action) => {
  if (action.type === Types.INITIAL) {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  return state;
};

type Props = {
  children: React.ReactNode;
};

export const AuthContext = createContext({} as FirebaseContextType);

export function AuthProvider({ children }: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(() => {
    try {
      onAuthStateChanged(AUTH, async (user) => {
        if (user) {
          const userProfile = doc(DB, "users", user.uid);

          const docSnap = await getDoc(userProfile);

          const profile = docSnap.data();

          dispatch({
            type: Types.INITIAL,
            payload: {
              user: {
                ...user,
                ...profile,
                id: user.uid,
              },
            },
          });
        } else {
          dispatch({
            type: Types.INITIAL,
            payload: {
              user: null,
            },
          });
        }
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: Types.INITIAL,
        payload: {
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const login = useCallback(async (email: string, password: string) => {
    await signInWithEmailAndPassword(AUTH, email, password);
  }, []);

  const loginWithGoogle = useCallback(async () => {
    const provider = new GoogleAuthProvider();

    await signInWithPopup(AUTH, provider);
  }, []);

  const loginWithGithub = useCallback(async () => {
    const provider = new GithubAuthProvider();

    await signInWithPopup(AUTH, provider);
  }, []);

  const loginWithTwitter = useCallback(async () => {
    const provider = new TwitterAuthProvider();

    await signInWithPopup(AUTH, provider);
  }, []);

  // REGISTER
  const register = useCallback(async (email: string, password: string) => {
    const newUser = await createUserWithEmailAndPassword(AUTH, email, password);

    await sendEmailVerification(newUser.user);

    const userProfile = doc(collection(DB, "users"), newUser.user?.uid);

    await setDoc(userProfile, {
      uid: newUser.user?.uid,
      email,
      plan: "free",
    });
  }, []);

  const sendVerificationEmail = async () => {
    if (AUTH.currentUser) {
      return await sendEmailVerification(AUTH.currentUser as User);
    } else {
      console.error("Not signed in yet");
      return;
    }
  };

  // LOGOUT
  const logout = useCallback(async () => {
    await signOut(AUTH);
  }, []);

  // FORGOT PASSWORD
  const forgotPassword = useCallback(async (email: string) => {
    await sendPasswordResetEmail(AUTH, email);
  }, []);

  // USER REFRESH
  const refreshUser = useCallback(async () => {
    if (state.user) {
      const userRef = doc(DB, "users", state.user.uid);
      const docSnap = await getDoc(userRef);
      const profile = docSnap.data();
      dispatch({
        type: Types.INITIAL,
        payload: {
          user: {
            ...state.user,
            ...profile,
          },
        },
      });
    } else {
      console.error("User not authenticated");
    }
  }, [state.user]);

  const changeAvailablePublic = useCallback(
    async (type: string) => {
      console.log("changeAvailablePublic", type);
      //userのavailablePublicの数を一つ減らします
      if (state.user) {
        const userRef = doc(DB, "users", state.user.uid);
        const docSnap = await getDoc(userRef);

        const profile = docSnap.data();
        const available_public = profile?.available_public;

        if (type === "increase") {
          await updateDoc(userRef, {
            available_public: available_public + 1,
          });
        } else {
          await updateDoc(userRef, {
            available_public: available_public - 1,
          });
        }
        //更新した結果に変更する
        const docSnap2 = await getDoc(userRef);
        const profile2 = docSnap2.data();
        dispatch({
          type: Types.INITIAL,
          payload: {
            user: {
              ...state.user,
              ...profile2,
            },
          },
        });
      } else {
        console.error("User not authenticated");
      }
    },
    [state.user]
  );

  // const checkAuthenticated = state.user?.emailVerified
  //   ? "authenticated"
  //   : "unauthenticated";

  const checkAuthenticated = state.user ? "authenticated" : "unauthenticated";

  const status = state.loading ? "loading" : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      emailVerified: state.user?.emailVerified,
      method: "firebase",
      loading: status === "loading",
      authenticated: status === "authenticated",
      unauthenticated: status === "unauthenticated",
      login,
      logout,
      register,
      forgotPassword,
      loginWithGoogle,
      loginWithGithub,
      loginWithTwitter,
      sendVerificationEmail,
      refreshUser,
      changeAvailablePublic,
    }),
    [
      status,
      state.user,
      login,
      logout,
      register,
      forgotPassword,
      loginWithGithub,
      loginWithGoogle,
      loginWithTwitter,
      refreshUser,
      changeAvailablePublic,
    ]
  );

  return (
    <AuthContext.Provider value={memoizedValue}>
      {children}
    </AuthContext.Provider>
  );
}

export const useAuthData = () => useContext(AuthContext) as FirebaseContextType;
