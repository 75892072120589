import { m } from "framer-motion";
import { alpha } from "@mui/material/styles";
import Box, { BoxProps } from "@mui/material/Box";
import Icon from "./Icon";

export default function SplashScreen({ sx, ...other }: BoxProps) {
  return (
    <Box
      sx={{
        right: 0,
        width: 1,
        bottom: 0,
        height: 1,
        zIndex: 9000,
        display: "flex",
        position: "fixed",
        alignItems: "center",
        justifyContent: "center",
        bgcolor: "background.default",
        ...sx,
      }}
      {...other}
    >
      <>
        <m.div
          animate={{
            scale: [1, 1.1, 1.1, 1, 1],
            opacity: [1, 0.5, 0.5, 1, 1],
          }}
          transition={{
            duration: 2,
            ease: "easeInOut",
            repeatDelay: 1,
            repeat: Infinity,
          }}
        >
          <Icon disabledLink sx={{ width: 82, height: 82 }} />
        </m.div>

        <Box
          component={m.div}
          animate={{
            scale: [1.5, 1, 1, 1.5, 1.5],
            rotate: [0, 360, 360, 0, 0],
            opacity: [0.2, 1, 1, 0.2, 0.2],
            borderRadius: ["20%", "20%", "50%", "50%", "20%"],
          }}
          transition={{ ease: "easeInOut", duration: 3, repeat: Infinity }}
          sx={{
            width: 100,
            height: 100,
            position: "absolute",
            border: (theme) =>
              `solid 4px ${alpha(theme.palette.primary.dark, 0.3)}`,
          }}
        />

        <Box
          component={m.div}
          animate={{
            scale: [1, 1.3, 1.3, 1, 1],
            rotate: [0, -360, -360, 0, 0],
            opacity: [1, 0.3, 0.3, 1, 1],
            borderRadius: ["20%", "20%", "50%", "50%", "20%"],
          }}
          transition={{
            ease: "easeInOut",
            duration: 3.5,
            repeat: Infinity,
          }}
          sx={{
            width: 120,
            height: 120,
            position: "absolute",
            border: (theme) =>
              `solid 6px ${alpha(theme.palette.primary.dark, 0.3)}`,
          }}
        />
      </>
    </Box>
  );
}
