import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { FormFields, IForm, IPreviewVideoChooseForm } from "src/types/video";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import FieldItem from "./FieldItem";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { getFirestore, addDoc, collection } from "firebase/firestore";

type Props = {
  videoChooseId: string;
  formId: string;
  fields: FormFields;
  form: IPreviewVideoChooseForm;
  videoRef: React.RefObject<HTMLVideoElement>;
  handleFormModalClose: () => void;
};

const validateEmail = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export default function VideoChooseForm({
  videoChooseId,
  formId,
  form,
  fields,
  videoRef,
  handleFormModalClose,
}: Props) {
  const [fieldValues, setFieldValues] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const [emptyErrorFieldIds, setEmptyErrorFieldIds] = useState<string[]>([]);

  // fieldsオブジェクトが変更されるたびにフィールドの状態を更新します。
  useEffect(() => {
    // fieldsオブジェクトから状態の初期値を生成します。
    const initialValues = Object.keys(fields).reduce(
      (acc: any, fieldId: string) => {
        // acc[fieldId] = fields[fieldId].defaultValue || '';
        acc[fieldId] = "";
        return acc;
      },
      {}
    );

    // 状態を更新します。
    setFieldValues(initialValues);
  }, [fields]);

  const handleInputChange = (fieldId: string, value: any) => {
    console.log("fieldId", fieldId);
    setFieldValues((prevValues: any) => ({
      ...prevValues,
      [fieldId]: value,
    }));
  };

  console.log("fieldValues", fieldValues);

  const handleSubmit = async () => {
    setLoading(true);

    /* fieldsの各要素のrequiredがtrueの場合は必須かどうか検証する */
    const requiredFields = Object.keys(fields).filter(
      (fieldId) => fields[fieldId].required
    );

    const emptyFields = requiredFields.filter(
      (fieldId) => !fieldValues[fieldId]
    );

    // names_separatedタイプのフィールドを検証
    const separatedFields = Object.keys(fields).filter(
      (fieldId) =>
        fields[fieldId].type === "names_separated" && fields[fieldId].required
    );

    const emptySeparatedFields = separatedFields.filter((fieldId) => {
      const value = fieldValues[fieldId];
      return !value || Object.keys(value).some((key) => !value[key]);
    });

    const checkboxFields = Object.keys(fields).filter(
      (fieldId) =>
        fields[fieldId].type === "checkbox" && fields[fieldId].required
    );

    const uncheckedCheckboxFields = checkboxFields.filter(
      (fieldId) => !fieldValues[fieldId]
    );

    // 結合したエラーチェック
    const combinedEmptyFields = [
      ...emptyFields,
      ...emptySeparatedFields,
      ...uncheckedCheckboxFields,
    ];

    if (combinedEmptyFields.length > 0) {
      enqueueSnackbar("必須項目を入力してください", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
      setEmptyErrorFieldIds(combinedEmptyFields);
      setLoading(false);
      return;
    }

    // Email フィールドの形式を検証
    const emailFieldsWithError = Object.keys(fields).filter((fieldId) => {
      const field = fields[fieldId];
      if (field.type === "email" && !validateEmail(fieldValues[fieldId])) {
        return true;
      }
      return false;
    });

    if (emailFieldsWithError.length > 0) {
      enqueueSnackbar("Email 形式が無効です", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
      setLoading(false);
      return;
    }

    const allValuesAreEmpty = Object.values(fieldValues).every(
      (value) => value === ""
    );

    if (allValuesAreEmpty) {
      enqueueSnackbar("入力内容を確認してください", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
      setLoading(false);
      return;
    }

    const db = getFirestore();
    const dataRef = collection(
      db,
      `videoChooses/${videoChooseId}/forms/${formId}/data`
    );
    try {
      const docRef = await addDoc(dataRef, {
        fieldValues,
        createdAt: new Date(),
      });

      enqueueSnackbar("送信が完了しました", {
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
      handleFormModalClose();
    } catch (e) {
      console.error("Error adding document: ", e);
      enqueueSnackbar("送信に失敗しました", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
    }
    setLoading(false);
  };
  return (
    <>
      <Paper
        sx={{
          position: "relative",
          width: "100%",
          height: "100%",
          background: "rgba(0,0,0,0.78)",
        }}
      >
        <Stack
          style={{
            width: "100%",
            height: "100%",
            paddingTop: 50,
            zIndex: 1000,
          }}
        >
          <Stack
            spacing={1}
            className="custom-scrollbar"
            sx={{
              py: 1,
              maxWidth: 380,
              width: "100%",
              alignItems: "center",
              webkitOverflowScrolling: "touch",
              overflowY: "auto",
              margin: "0 auto",
            }}
            onTouchMove={(e) => {
              e.stopPropagation();
            }}
          >
            <Stack
              flexDirection="column"
              justifyContent="space-between"
              alignItems="center"
              alignSelf="center"
              sx={{ maxWidth: 380, width: "100%", paddingTop: 2 }}
            >
              {form.is_title_displayed && (
                <Box
                  sx={{
                    backgroundColor: "rgba(0,0,0,0.2)",
                    padding: "0 6px",
                    margin: "0 auto",
                    maxWidth: 380,
                    width: "80%",
                    marginBottom: 2,
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{
                      color: "#fff",
                      textAlign: "center",
                      fontSize: "0.85rem",
                    }}
                  >
                    {form.title}
                  </Typography>
                </Box>
              )}

              <Box
                sx={{
                  backgroundColor: "rgba(0,0,0,0.2)",
                  padding: "0 6px",
                  margin: "0 auto",
                  maxWidth: 380,
                  width: "80%",
                  marginBottom: 2,
                }}
              >
                <Typography
                  variant="body2"
                  sx={{ color: "#fff", textAlign: "left", fontSize: "0.85rem" }}
                >
                  {form.description}
                </Typography>
              </Box>
            </Stack>

            {form.order.map((fieldId: string, fieldIndex: number) => (
              <FieldItem
                key={fieldId}
                field={fields[fieldId]}
                value={fieldValues[fieldId] as any}
                onChange={(value) => handleInputChange(fieldId, value)}
                error={emptyErrorFieldIds.includes(fieldId)}
              />
            ))}
            <Box
              sx={{
                maxWidth: 380,
                width: "100%",
                alignItems: "center",
                alignSelf: "center",
                bottom: "12px",
                margin: "0 auto",
              }}
            >
              <Button
                sx={{
                  backgroundColor: form.send_color || "#66c5c7",
                  color: "#fff",
                  borderRadius: "100px",
                  padding: "6px 12px",
                  margin: "0 auto",
                  display: "block",
                  width: "87%",
                  height: 50,
                  "&:hover": {
                    backgroundColor: form.send_color || "#66c5c7",
                  },
                }}
                onClick={() => {
                  handleSubmit();
                }}
              >
                {loading ? (
                  <CircularProgress size={24} />
                ) : (
                  <Typography variant="body2" sx={{ color: "#fff" }}>
                    送信する
                  </Typography>
                )}
              </Button>
              <Box sx={commonStyles.footerBox}>
                <Box
                  style={{
                    fontSize: 13,
                    fontFamily: '"Open Sans", sans-serif',
                  }}
                >
                  Powered by &nbsp;
                </Box>
                <Button
                  onClick={() => {
                    if (videoRef.current) {
                      videoRef.current.pause();
                    }
                    window.open(
                      `${process.env.REACT_APP_CONSUMER_URL}`,
                      "_blank"
                    );
                  }}
                  sx={{
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    padding: 0,
                  }}
                >
                  <img
                    src={"/assets/footer-logo.png"}
                    style={{ width: 125, borderRadius: 1.5 }}
                  />
                </Button>
              </Box>
            </Box>
          </Stack>
        </Stack>
      </Paper>
    </>
  );
}

const commonStyles = {
  footerBox: {
    position: "relative",
    cursor: "pointer",
    alignItems: "center",
    justifyContent: "center",
    transition: "all .15s 0s cubic-bezier(.25,.46,.45,.94)",
    borderRadius: "2rem",
    height: "2.6rem",
    padding: "1rem 0",
    fontWeight: 500,
    color: "#fff",
    textDecoration: "none",
    display: "inline-flex",
    width: "100%",
    opacity: 0.5,
    marginTop: 2,
  },
};
