import { forwardRef } from "react";
import { useTheme } from "@mui/material/styles";
import Link from "@mui/material/Link";
import Box, { BoxProps } from "@mui/material/Box";
import RouterLink from "src/routes/RouterLink";

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx, ...other }, ref) => {
    const theme = useTheme();

    const logo = (
      <Box
        component="img"
        src="/assets/brand-logo.png"
        sx={{ width: 210, height: 19.4, cursor: "pointer", ...sx }}
      />
    );

    if (disabledLink) {
      return logo;
    }

    return (
      <Link component={RouterLink} href="/" sx={{ display: "contents" }}>
        {logo}
      </Link>
    );
  }
);

export default Logo;
