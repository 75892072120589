import Stack from "@mui/material/Stack";
import Paper, { PaperProps } from "@mui/material/Paper";
import { FormField } from "src/types/video";
import { TextField, Typography } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import Iconify from "src/components/Iconify";
import { useState } from "react";
import { DesktopTimePicker } from "@mui/x-date-pickers";

type SeparatedProps = {
  familyName: string;
  givenName: string;
};

type Props = PaperProps & {
  field: FormField;
  value: string | number | boolean | SeparatedProps | Date;
  onChange: (
    value: string | number | boolean | SeparatedProps | Date | null
  ) => void;
  error: boolean;
};

const validateEmail = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export default function FieldItem({ field, value, onChange, error }: Props) {
  const [emailError, setEmailError] = useState<boolean>(false);

  //Done
  const renderTextField = (
    <TextField
      sx={{
        width: "100%",
        borderRadius: 2,
        backgroundColor: "#fff!important",
        "& .MuiFilledInput-root": {
          backgroundColor: "#fff", // 白背景を設定
          "&:before, &:after": { borderBottom: "none" }, // 下線を非表示に
          "&:hover:before": { borderBottom: "none !important" }, // ホバー時の下線を非表示に
        },
        "& .MuiInput-underline:before, & .MuiInput-underline:after, & .MuiInput-underline:hover:before":
          {
            borderBottom: "none", // 下線を非表示に（非filledバリアント向けのスタイル調整）
          },
        "& .MuiInputLabel-root": {
          color: "#949494!important",
        },
      }}
      inputProps={{
        maxLength: 200,
      }}
      required={field.required}
      label={field.label}
      size="small"
      variant="filled"
      type="text"
      value={value || ""}
      onChange={(e) => onChange(e.target.value)}
      error={error}
    />
  );

  //Done
  const renderNameSeparatedField = (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <TextField
        sx={{
          width: "48%",
          borderRadius: 2,

          backgroundColor: "#fff!important",

          "& .MuiFilledInput-underline:before": {
            borderBottom: "none", // フォーカスされていないときの下線を消す
            textDecoration: "none",
          },
          "& .MuiFilledInput-underline:after": {
            borderBottom: "none", // フォーカスされたときの下線を消す
            textDecoration: "none",
          },
          "& .MuiFilledInput-underline:hover:before": {
            borderBottom: "none", // ホバー時の下線を消す
            textDecoration: "none",
          },

          "& .MuiFilledInput-underline:hover:after": {
            borderBottom: "none", // ホバー時の下線を消す
            textDecoration: "none",
          },
          "& .MuiInputLabel-root": {
            color: "#949494!important",
          },
          "& .MuiFilledInput-root": {
            backgroundColor: "#fff!important",
            borderRadius: 2,
          },
        }}
        inputProps={{
          maxLength: 200,
        }}
        label={"姓"}
        size="small"
        variant="filled"
        value={(value as SeparatedProps)?.familyName || ""}
        onChange={(e) => {
          const values: SeparatedProps = (value as SeparatedProps) || {
            familyName: "",
            givenName: "",
          };
          values.familyName = e.target.value;
          onChange(values);
        }}
        required={field.required}
        error={error && !(value as SeparatedProps)?.familyName}
      />
      <TextField
        sx={{
          width: "48%",
          borderRadius: 2,

          backgroundColor: "#fff!important",

          "& .MuiInputLabel-root": {
            color: "#949494!important",
          },

          "& .MuiFilledInput-underline:before": {
            borderBottom: "none", // フォーカスされていないときの下線を消す
            textDecoration: "none",
          },
          "& .MuiFilledInput-underline:after": {
            borderBottom: "none", // フォーカスされたときの下線を消す
            textDecoration: "none",
          },
          "& .MuiFilledInput-underline:hover:before": {
            borderBottom: "none", // ホバー時の下線を消す
            textDecoration: "none",
          },
          "& .MuiFilledInput-underline:hover:after": {
            borderBottom: "none", // ホバー時の下線を消す
            textDecoration: "none",
          },
          "& .MuiFilledInput-root": {
            backgroundColor: "#fff!important",
            borderRadius: 2,
          },
        }}
        label={"名"}
        inputProps={{
          maxLength: 200,
        }}
        size="small"
        variant="filled"
        value={(value as SeparatedProps)?.givenName || ""}
        onChange={(e) => {
          const values: SeparatedProps = (value as SeparatedProps) || {
            familyName: "",
            givenName: "",
          };
          values.givenName = e.target.value;
          onChange(values);
        }}
        required={field.required}
        error={error && !(value as SeparatedProps)?.givenName}
      />
    </Stack>
  );

  //Done
  const renderEmailField = (
    <>
      <TextField
        sx={{
          width: "100%",
          borderRadius: 2,
          backgroundColor: "#fff!important",
          "& .MuiInputLabel-root": {
            color: "#949494!important",
          },

          "& .MuiFilledInput-underline:before": {
            borderBottom: "none", // フォーカスされていないときの下線を消す
          },
          "& .MuiFilledInput-underline:after": {
            borderBottom: "none", // フォーカスされたときの下線を消す
          },
          "& .MuiFilledInput-underline:hover:before": {
            borderBottom: "none", // ホバー時の下線を消す
          },
          "& .MuiFilledInput-underline:hover:after": {
            borderBottom: "none", // ホバー時の下線を消す
          },
          "& .MuiFilledInput-root": {
            backgroundColor: "#fff!important",
            borderRadius: 2,
          },
        }}
        inputProps={{
          maxLength: 200,
        }}
        label={field.label}
        size="small"
        variant="filled"
        type="email"
        value={value || ""}
        required={field.required}
        onChange={(e) => onChange(e.target.value)}
        // onBlur={(e) => {
        //   const email = e.target.value;
        //   const isValidEmail = validateEmail(email);
        //   setEmailError(!isValidEmail);
        // }}
        error={emailError || error}
        // error={error}
      />
      {/* {emailError && (
        <Typography color="error" sx={{ fontSize: 12, mt: 1 }}>
          無効な Email 形式です
        </Typography>
      )} */}
    </>
  );

  //Done
  const renderTelField = (
    <TextField
      sx={{
        width: "100%",
        borderRadius: 2,
        backgroundColor: "#fff!important",
        "& .MuiInputLabel-root": {
          color: "#949494!important",
        },

        "& .MuiFilledInput-underline:before": {
          borderBottom: "none", // フォーカスされていないときの下線を消す
          textDecoration: "none",
        },
        "& .MuiFilledInput-underline:after": {
          borderBottom: "none", // フォーカスされたときの下線を消す
          textDecoration: "none",
        },
        "& .MuiFilledInput-underline:hover:before": {
          borderBottom: "none", // ホバー時の下線を消す
          textDecoration: "none",
        },
        "& .MuiFilledInput-underline:hover:after": {
          borderBottom: "none", // ホバー時の下線を消す
          textDecoration: "none",
        },
        "& .MuiFilledInput-root": {
          backgroundColor: "#fff!important",
          borderRadius: 2,
        },
      }}
      inputProps={{
        maxLength: 200,
      }}
      label={field.label}
      size="small"
      variant="filled"
      type="tel"
      value={value || ""}
      required={field.required}
      onChange={(e) => onChange(e.target.value)}
      error={error}
    />
  );

  //Done
  const renderCheckboxField = (
    <FormControlLabel
      required={field.required}
      control={
        <Checkbox
          checked={!!value} // チェック状態をバインド
          onChange={(e) => onChange(e.target.checked)}
          icon={
            <span
              style={{
                borderRadius: 6,
                width: 24,
                height: 24,
                backgroundColor: "#fff",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: "2px",

                ...(error && {
                  borderColor: "red",
                  borderWidth: 2,
                  borderStyle: "solid",
                }),
              }}
            />
          } // チェックされていない時のカスタムアイコン
          checkedIcon={
            <span
              style={{
                borderRadius: 6,
                width: 24,
                height: 24,
                backgroundColor: "#fff",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: "2px",
              }}
            >
              <Iconify
                icon={"ic:round-check"}
                sx={{ color: "#333" }}
                width={20}
                height={20}
              />
            </span>
          } // チェックされた時のカスタムアイコン
        />
      }
      label={field.label}
      sx={{ color: "#fff" }}
    />
  );

  //Done
  const renderSelectField = (
    <FormControl
      required={field.required}
      variant="filled"
      sx={{ width: "100%", height: "48px" }}
      error={error}
    >
      <InputLabel
        sx={{
          transform: "translate(12px, 13px) scale(1)",
          color: "#949494!important",
          "& .MuiInputLabel-root": {
            color: "#949494!important",
          },

          "&.MuiInputLabel-shrink": {
            transform: "translate(12px, 4px) scale(0.75)",
            color: "#949494!important",
          },
        }}
      >
        {field.label}
      </InputLabel>
      <Select
        value={value || ""}
        onChange={(e) => onChange(e.target.value)}
        sx={{
          backgroundColor: "#fff!important",
          "& .MuiInputLabel-root": {
            color: "#949494!important",
          },
          "& .Mui-focused .MuiInputLabel-root": {
            color: "#949494!important",
          },
          height: "48px",
        }}
      >
        <MenuItem value="">選択してください</MenuItem>

        {field.options?.map((option, index) => (
          <MenuItem key={index} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  const renderDateTimeField = (
    <DateTimePicker
      label={field.label}
      sx={{
        width: "100%",
        borderRadius: 2,
        backgroundColor: "#fff!important",
        color: "#949494!important",
        "& .MuiInputLabel-shrink": {
          color: "#949494!important",
        },
        "& .MuiFilledInput-root": {
          backgroundColor: "#fff!important",
        },
      }}
      slotProps={{
        textField: {
          variant: "filled",
        },
      }}
      value={(value as Date) || null}
      onChange={(date) => onChange(date)}
    />
  );

  const renderDateField = (
    <DatePicker
      label={field.label}
      sx={{
        width: "100%",
        height: "48px",
        backgroundColor: "#fff!important",
        color: "#949494!important",
        "& .MuiInputLabel-shrink": {
          color: "#949494!important",
        },
        "& .MuiFilledInput-root": {
          backgroundColor: "#fff!important",
        },
        "& .MuiInputLabel-root": {
          color: "#949494!important",
        },
      }}
      slotProps={{
        textField: {
          variant: "filled",
          required: field.required,
          error: error,
        },
      }}
      value={(value as Date) || null}
      onChange={(date) => onChange(date)}

      // renderInput={(params) => (
      //   <TextField
      //     {...params}
      //     fullWidth
      //     required={field.required}
      //     error={error}
      //     helperText={error ? "このフィールドは必須です" : ""}
      //   />
      // )}
    />
  );
  const renderTimeField = (
    <DesktopTimePicker
      label={field.label}
      sx={{
        width: "100%",
        height: "48px",
        backgroundColor: "#fff!important",
        color: "#949494!important",
        "& .MuiInputLabel-shrink": {
          color: "#949494!important",
        },
        "& .MuiFilledInput-root": {
          backgroundColor: "#fff!important",
        },
        "& .MuiInputLabel-root": {
          color: "#949494!important",
        },
      }}
      slotProps={{
        textField: {
          variant: "filled",
          required: field.required,
          error: error,
        },
      }}
      value={(value as Date) || null}
      onChange={(date) => onChange(date)}
    />
  );

  const renderTextAreaField = (
    <TextField
      multiline={true}
      sx={{
        width: "100%",
        borderRadius: 2,
        backgroundColor: "#fff!important",
        "& .MuiInput-underline:before, & .MuiInput-underline:after, & .MuiInput-underline:hover:before, & .MuiInput-underline:hover:after, & .MuiFilledInput-underline:before, & .MuiFilledInput-underline:after, & .MuiFilledInput-underline:hover:before, & .MuiFilledInput-underline:hover:after":
          {
            borderBottom: "none",
          },
        "&:hover .MuiInput-underline:before, &:hover .MuiInput-underline:after, &:hover .MuiFilledInput-underline:before, &:hover .MuiFilledInput-underline:after":
          {
            borderBottom: "none !important",
          },
        "& .MuiInputLabel-root": {
          color: "#949494!important",
        },
        "& .MuiInputLabel-shrink": {
          color: "#949494!important",
        },
        "& .MuiFilledInput-root": {
          backgroundColor: "#fff!important",
        },
      }}
      inputProps={{
        maxLength: 1000,
      }}
      rows={3}
      required={field.required}
      label={field.label}
      size="small"
      variant="filled"
      type="text"
      value={value || ""}
      onChange={(e) => onChange(e.target.value)}
      error={error}
    />
  );

  return (
    <>
      <Paper
        sx={{
          maxWidth: 380,
          width: "87%",
          minHeight: field.type === "textarea" ? 95 : 48,
          overflow: "hidden",
          position: "relative",
          borderRadius: 2,
          boxShadow: "none",
          backgroundColor: "transparent",
          marginTop: "0px!important",
          marginBottom: "16px!important",
        }}
      >
        <>
          {field.type === "text" && renderTextField}
          {field.type === "names_separated" && renderNameSeparatedField}
          {field.type === "email" && renderEmailField}
          {field.type === "select" && renderSelectField}
          {field.type === "checkbox" && renderCheckboxField}
          {field.type === "datetime" && renderDateTimeField}
          {field.type === "date" && renderDateField}
          {field.type === "time" && renderTimeField}
          {field.type === "tel" && renderTelField}
          {field.type === "textarea" && renderTextAreaField}
        </>
      </Paper>
    </>
  );
}
