import { AppBar, Toolbar, Stack, IconButton } from "@mui/material";
import { useResponsive } from "src/hooks/useResponsive";
import { useOffSetTop } from "src/hooks/useOffSetTop";
import AccountPopover from "../AccountPopover";
import Iconify from "../Iconify";

type Props = {
  onOpenNav?: VoidFunction;
};

const Header = ({ onOpenNav }: Props) => {
  const lgUp = useResponsive("up", "lg");

  const offset = useOffSetTop(80);

  const renderContent = (
    <>
      {!lgUp && (
        <IconButton onClick={onOpenNav}>
          <Iconify icon="mingcute:menu-fill" fontSize="24" />
        </IconButton>
      )}
      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1 }}
      >
        <AccountPopover />
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        height: 64,
        zIndex: 100,
        backgroundColor: "#f1f3f9",

        boxShadow: "none",
        ...(lgUp && {
          width: `calc(100% - 281px)`,
          height: 80,
          ...(offset && {
            height: 64,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
