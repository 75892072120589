import Stack from "@mui/material/Stack";

import { gradationColors } from "src/theme/gradation";

type Props = {
  children: React.ReactNode;
};

export default function AuthLayout({ children }: Props) {
  return (
    <Stack
      component="main"
      direction="row"
      sx={{
        minHeight: "100vh",
        background: gradationColors,
      }}
    >
      {/* <Header /> */}
      <Stack
        sx={{
          width: 1,
          mx: "auto",
          maxWidth: 520,
          px: { xs: 2, md: 8 },
          py: { xs: 15, md: 10 },
        }}
      >
        {children}
      </Stack>
    </Stack>
  );
}
