import { useCallback, useEffect } from "react";
import { paths } from "src/routes/paths";
import { useAuthData } from "../firebase/AuthProvider";
import { useRouter } from "src/hooks/useRouter";

type Props = {
  children: React.ReactNode;
};

export default function GuestGuard({ children }: Props) {
  const router = useRouter();

  const { authenticated } = useAuthData();
  console.log("authenticated", authenticated);

  const check = useCallback(() => {
    if (authenticated) {
      router.replace(paths.dashboard.root);
    }
  }, [authenticated, router]);

  useEffect(() => {
    check();
  }, [check]);

  return <>{children}</>;
}
