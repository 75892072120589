import { useEffect, useCallback, useState } from "react";
import { paths } from "src/routes/paths";
import { useAuthData } from "../firebase/AuthProvider";
import { useRouter } from "src/hooks/useRouter";
import { lazy, Suspense } from "react";

import AuthLayout from "src/layouts/AuthLayout";

const SendEmailVerification = lazy(
  () => import("src/pages/auth/SendEmailVerification")
);

type Props = {
  children: React.ReactNode;
};

export default function AuthGuard({ children }: Props) {
  const router = useRouter();

  const { authenticated, emailVerified } = useAuthData();

  const [checked, setChecked] = useState(false);

  const check = useCallback(() => {
    if (!authenticated) {
      //not login
      const searchParams = new URLSearchParams({
        returnTo: window.location.pathname,
      }).toString();

      const loginPath = paths.auth.login;

      const href = `${loginPath}?${searchParams}`;

      router.replace(href);
    } else {
      //already login

      if (!emailVerified) {
        router.replace(paths.emailAuth.emailSend);
      } else {
        setChecked(true);
      }
    }
  }, [authenticated, router]);

  useEffect(() => {
    check();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!checked) {
    return null;
  }

  return <>{children}</>;
}
