import Box, { BoxProps } from "@mui/material/Box";
import { useResponsive } from "src/hooks/useResponsive";

export default function Main({ children, sx, ...other }: BoxProps) {
  const lgUp = useResponsive("up", "lg");

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        minHeight: 1,
        display: "flex",
        flexDirection: "column",
        py: `72px`,
        ...(lgUp && {
          px: 2,
          py: `88px`,
          width: `calc(100% - 280px)`,
        }),
        ...sx,
      }}
      {...other}
    >
      {children}
    </Box>
  );
}
