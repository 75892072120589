import { useEffect, useRef, useState } from "react";
import {
  getFirestore,
  doc,
  getDoc,
  getDocs,
  collection,
} from "firebase/firestore";
import { useParams } from "src/hooks/useParams";
import {
  boardProps,
  nextActionsType,
  videoType,
  videosType,
  IVideoChooseForm,
  FormFields,
} from "src/types/video";

import LinearProgress from "@mui/material/LinearProgress";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import Iconify from "src/components/Iconify";
import { useSnackbar } from "notistack";
import { useResponsive } from "src/hooks/useResponsive";
import Modal from "@mui/material/Modal";
import VideoChooseForm from "./VideoChoosePreviewComponent/VideoChooseForm";
import Fade from "@mui/material/Fade";
import { useAuthData } from "src/auth/firebase/AuthProvider";

interface MessageData {
  action: "play" | "pause";
}

const sendMessage = (
  anchor: string | undefined,
  is_close_anchor_click: boolean | undefined
) => {
  window.parent.postMessage(
    {
      action: "navigate",
      anchor: `#${anchor}`,
      close: is_close_anchor_click,
    },
    "*"
  );
};

// for subtitle
interface SubtitleProps {
  subtitleUrl: string | null;
  currentTime: number;
  isOptions: boolean;
  opacity: number;
}

const parseTimestamp = (timestamp: string) => {
  const [hours, minutes, seconds] = timestamp.split(":").map(parseFloat);
  return hours * 3600 + minutes * 60 + seconds;
};

const Subtitle: React.FC<SubtitleProps> = ({
  subtitleUrl,
  currentTime,
  isOptions,
  opacity,
}) => {
  const [subtitles, setSubtitles] = useState<
    { start: number; end: number; text: string }[]
  >([]);

  useEffect(() => {
    const fetchSubtitles = async () => {
      if (subtitleUrl) {
        const response = await fetch(subtitleUrl);
        const data = await response.json();

        // データが配列であることを確認
        if (Array.isArray(data)) {
          // 配列の各要素が連想配列であることを確認
          const isAssociativeArray = data.every(
            (item) => typeof item === "object" && item !== null
          );

          if (isAssociativeArray) {
            // データを適切な型に変換
            const parsedSubtitles = data.map((item) => ({
              start: parseTimestamp(item.start),
              end: parseTimestamp(item.end),
              text: item.text,
            }));

            setSubtitles(parsedSubtitles);
          } else {
            console.log(
              "The elements of the data array are not associative arrays"
            );
          }
        } else {
          console.log("The data is not an array");
        }
      } else {
        setSubtitles([]);
      }
    };

    fetchSubtitles();
  }, [subtitleUrl]);

  const currentSubtitle = subtitles.find(
    (subtitle) => currentTime >= subtitle.start && currentTime <= subtitle.end
  );

  return (
    <div
      style={{
        position: isOptions ? "static" : "absolute",
        minWidth: "50%",
        textAlign: "center",
        zIndex: 300,
        opacity: isOptions ? 1 - opacity : opacity,
        width: "100%",
        maxWidth: 320,
        ...(isOptions && {
          margin: "10px auto",
        }),
        ...(!isOptions && {
          bottom: "120px",
          left: "50%",
          transform: "translateX(-50%)",
        }),
      }}
    >
      {currentSubtitle ? (
        <div
          style={{
            backgroundColor: "rgba(20, 20, 20, 0.5)",
            color: "white",
            padding: "5px 10px",
            borderRadius: "5px",
            fontSize: 13,
            minHeight: 30,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            maxWidth: 320,
            margin: "0 auto",
          }}
        >
          <span style={{ fontFamily: '"Open Sans", sans-serif' }}>
            {currentSubtitle.text}
          </span>
        </div>
      ) : (
        subtitleUrl && (
          <div
            style={{
              backgroundColor: "transparent",
              padding: "5px 10px",
              minHeight: 30,
              maxWidth: 320,
              margin: "0 auto",
            }}
          ></div>
        )
      )}
    </div>
  );
};

// for subtitle end

export default function VideoChoosePreviewPage() {
  const { user } = useAuthData();
  //動画タグのref
  const videoRef = useRef<HTMLVideoElement>(null);
  const mdUp = useResponsive("up", "md");

  //選択肢のボックスのref
  const boxRef = useRef<HTMLDivElement>(null);

  //URLからvideoChooseIdを取得
  const params = useParams();
  const { videoChooseId } = params;

  //IFrame読み込みかどうか
  const isIFrame = window.self !== window.top || window.frameElement !== null;

  //videoChooseインスタンス
  const [videoChoose, setVideoChoose] = useState<boardProps>({
    videos: {},
    nextActions: {},
    videosOrdered: [],
  });
  const [opacity, setOpacity] = useState(1); // 透明度の状態

  //再生されるvideo
  const [video, setVideo] = useState<videoType | null>(null);

  //ProgressBarの値
  const [progress, setProgress] = useState<number>(0);

  //ローディング状態
  const [loading, setLoading] = useState<boolean>(true);

  //Videoローディング状態
  const [videoLoading, setVideoLoading] = useState<boolean>(false);

  //選択肢のトグルボタンの表示
  const [isShowButton, setIsShowButton] = useState<boolean>(false);

  //選択ボタンの透明度
  const [buttonOpacity, setButtonOpacity] = useState(1);

  //再生ボタンの透明度
  const [playButtonOpacity, setPlayButtonOpacity] = useState(1);

  //動画の終了状況の確認
  const [isVideoEnd, setIsVideoEnd] = useState(false);

  //動画再生時に選択肢を自動で閉じるかどうか
  const [autoClose, setAutoClose] = useState<boolean>(false);

  //ProgressBarのアニメーション
  const [progressTransition, setProgressTransition] = useState("0.3s linear");

  //自動再生(制御に使用するのみ、最初の動画は再生せず、選択肢を押した後の動画は自動再生できるようにする)
  const [autoPlay, setAutoPlay] = useState<boolean>(false);

  //動画のミュート
  const [isMuted, setIsMuted] = useState<boolean>(false);

  //動画再生履歴
  const [history, setHistory] = useState<string[]>([]);

  const [forms, setForms] = useState<IVideoChooseForm>({});

  const [formModal, setFormModal] = useState<boolean>(false);
  const [selectedFormId, setSelectedFormId] = useState<string>("");

  //通知バー
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const handleTouchMove = (event: TouchEvent) => {
      // event.targetがテキストエリアのrefであるかを確認
      if (boxRef.current && boxRef.current.contains(event.target as Node)) {
        // テキストエリア内でのイベントは通常通り処理（ここでは何もしない）
      } else {
        // テキストエリア外でのスクロールを防ぐ
        event.preventDefault();
      }
    };

    // touchmoveイベントでページ全体のスクロールを制御
    document.addEventListener("touchmove", handleTouchMove, { passive: false });

    // コンポーネントがアンマウントされる時にイベントリスナーをクリーンアップ
    return () => {
      document.removeEventListener("touchmove", handleTouchMove);
    };
  }, []);

  //選択肢がスクロールされると透明度を変雨する
  const handleScroll = (event: React.UIEvent<Element>) => {
    const target = event.target as HTMLElement;
    const scrollY = target.scrollTop;

    const opacity = Math.max(1 - scrollY / 50, 0);

    const toggleOpacity = 1 - Math.max(1 - scrollY / 50, 0);

    // boxRefの透明度を更新
    if (boxRef.current) {
      boxRef.current.style.opacity = toggleOpacity.toString();
    }

    setButtonOpacity(opacity);
    if (opacity == 0) {
      setIsShowButton(false);
    } else {
      setIsShowButton(true);
    }
  };

  //選択肢の矢印ボタンを押したら選択肢を画面上に表示させる
  const handleScrollToTop = () => {
    const start = boxRef.current ? boxRef.current.scrollTop : 0;
    const change = 400 - start; // スクロールする距離
    const duration = 400; // アニメーション時間を400ミリ秒に設定
    let currentTime = 0;
    const increment = 20;

    const animateScroll = () => {
      currentTime += increment;
      const val = easeInOutCubic(currentTime, start, change, duration);
      const opacity = easeInOutCubic(currentTime, 0.5, 1, duration); // 透明度を0から1へ変化させる

      if (boxRef.current) {
        boxRef.current.scrollTop = val;
        boxRef.current.style.opacity = opacity.toString(); // 透明度を適用
      }

      if (currentTime < duration) {
        requestAnimationFrame(animateScroll);
      }
    };

    const easeInOutCubic = (t: number, b: number, c: number, d: number) => {
      t /= d;
      t--;
      return c * (t * t * t + 1) + b;
    };

    animateScroll();
  };

  //再生を押したら選択肢を画面下に隠す
  const handleScrollToBottom = () => {
    const start = boxRef.current ? boxRef.current.scrollTop : 0;
    const change = -400; // スクロールする距離を逆にする
    const duration = 600; // アニメーション時間は400ミリ秒
    let currentTime = 0;
    const increment = 20;

    const animateScroll = () => {
      currentTime += increment;
      const val = easeInOutCubic(currentTime, start, change, duration);
      const opacity = 1 - easeInOutCubic(currentTime, 0, 0.5, duration); // 透明度を1から0へ変化させる

      if (boxRef.current) {
        boxRef.current.scrollTop = val;
        boxRef.current.style.opacity = opacity.toString(); // 透明度を適用
      }

      if (currentTime < duration) {
        requestAnimationFrame(animateScroll);
      }
    };

    const easeInOutCubic = (t: number, b: number, c: number, d: number) => {
      t /= d;
      t--;
      return c * (t * t * t + 1) + b;
    };

    animateScroll();
  };

  //最初に動画の選択肢を表示させる
  useEffect(() => {
    const start = boxRef.current ? boxRef.current.scrollTop : 0;
    const change = 300 - start;
    const duration = 1;
    let currentTime = 0;
    const increment = 20;

    const animateScroll = () => {
      currentTime += increment;
      const val = easeInOutCubic(currentTime, start, change, duration);
      const opacity = easeInOutCubic(currentTime, 0.5, 1, duration);

      if (boxRef.current) {
        boxRef.current.scrollTop = val;
        boxRef.current.style.opacity = opacity.toString();
      }

      if (currentTime < duration) {
        requestAnimationFrame(animateScroll);
      }
    };

    const easeInOutCubic = (t: number, b: number, c: number, d: number) => {
      t /= d;
      t--;
      return c * (t * t * t + 1) + b;
    };

    if (boxRef.current && video) {
      animateScroll();
    }
  }, [boxRef.current, video]);

  //progressの値を更新する関数
  //imageのカバーを透明にする
  const handleTimeUpdate = () => {
    const videoEl = videoRef.current;
    if (videoEl && videoEl.currentTime > 0) {
      setVideoLoading(false);
      setOpacity(0);
    }
    if (videoEl) {
      const currentProgress = (videoEl.currentTime / videoEl.duration) * 100;
      setProgress(currentProgress);
    }
  };

  //再生中にprogressの値を更新する関数を実行する
  useEffect(() => {
    const videoEl = videoRef.current;
    if (videoEl) {
      videoEl.addEventListener("timeupdate", handleTimeUpdate);

      return () => {
        videoEl.removeEventListener("timeupdate", handleTimeUpdate);
      };
    }
  }, [video, videoChoose]);

  /*
  動画が終了した時に、ProgressBarのアニメーションを停止する
  isVideoEndがtrueになると、巻き戻しボタンが表示される
  */
  useEffect(() => {
    const videoEl = videoRef.current;

    if (videoEl) {
      const handleVideoEnd = (): void => {
        setIsVideoEnd(true);
        setProgressTransition("none");
        handleScrollToTop();
      };

      videoEl.addEventListener("ended", handleVideoEnd);

      return () => {
        videoEl.removeEventListener("ended", handleVideoEnd);
      };
    }
  }, [video, videoChoose, progressTransition]);

  //巻き戻しボタンが実行された時に、ProgressBarのアニメーションを再開する
  useEffect(() => {
    if (progress === 0) {
      setProgressTransition("0.3s linear");
    }
  }, [progress]);

  //videoChooseIdから特定のvideoChooseを取得する
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        if (videoChooseId) {
          const db = getFirestore();
          const docRef = doc(db, "videoChooses", videoChooseId);
          const docSnap = await getDoc(docRef);

          let videos: videosType = {};
          let nextActions: nextActionsType = {};
          let videosOrdered: string[] = [];
          if (docSnap.exists() && docSnap.data()?.created_by === user?.uid) {
            const videosCollectionRef = collection(docRef, "videos");
            const videosSnapshot = await getDocs(videosCollectionRef);
            const videosAll = await Promise.all(
              videosSnapshot.docs.map(async (videoDoc) => {
                videos[videoDoc.id] = {
                  id: videoDoc.id,
                  nextActions: videoDoc.data().order,
                  video_url: videoDoc.data().video_url,
                  video_title: videoDoc.data().video_title,
                  thumbnail: videoDoc.data().thumbnail,
                  subtitle_url: videoDoc.data().subtitle_url || null,
                };

                //サムネイルのみ先読み込み
                const img = new Image();
                img.src = videoDoc.data().thumbnail;

                const videoData = videoDoc.data();

                const nextActionsCollectionRef = collection(
                  videoDoc.ref,
                  "nextActions"
                );

                const nextActionsSnapshot = await getDocs(
                  nextActionsCollectionRef
                );
                nextActionsSnapshot.docs.forEach((doc) => {
                  nextActions[doc.id] = { id: doc.id, ...doc.data() };
                });

                return { video_id: videoDoc.id, ...videoData, nextActions };
              })
            );

            videosOrdered = docSnap.data().order;

            setAutoClose(docSnap.data()?.auto_close_options);

            setVideoChoose({ videos, nextActions, videosOrdered });
          } else {
            console.log("No such document!");
          }
        }
      } catch (error) {
        if (error.code === "permission-denied") {
          console.log(
            "Access denied: You don't have permission to access this data."
          );
        } else {
          console.error("Error fetching data:", error);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [videoChooseId]);

  //videoChooseIdから特定のvideoChooseを取得する
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (videoChooseId) {
          const db = getFirestore();
          const docRef = doc(db, "videoChooses", videoChooseId);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            const formsCollectionRef = collection(docRef, "forms");
            const formsSnapshot = await getDocs(formsCollectionRef);

            formsSnapshot.docs.map(async (formDoc) => {
              const fieldsCollectionRef = collection(formDoc.ref, "fields");
              const fieldsSnapshot = await getDocs(fieldsCollectionRef);
              const fields: FormFields = fieldsSnapshot.docs.reduce(
                (acc, fieldDoc) => {
                  const field = {
                    id: fieldDoc.id,
                    type: fieldDoc.data().type,
                    fieldName: fieldDoc.data().fieldName,
                    label: fieldDoc.data().label,
                    required: fieldDoc.data().required,
                    ...(fieldDoc.data().options
                      ? { options: fieldDoc.data().options }
                      : {}),
                  };
                  return { ...acc, [fieldDoc.id]: field };
                },
                {}
              );

              const form: IVideoChooseForm = {
                [formDoc.id]: {
                  title: formDoc.data().title,
                  description: formDoc.data().description,
                  fields: fields,
                  send_color: formDoc.data().send_color,
                  order: formDoc.data().order,
                  is_title_displayed: formDoc.data().is_title_displayed,
                },
              };

              setForms((prevForms) => {
                return { ...prevForms, ...form };
              });
            });
          } else {
            console.log("No such document!");
          }
        }
      } catch (error) {
        if (error.code === "permission-denied") {
          console.log(
            "Access denied: You don't have permission to access this data."
          );
        } else {
          console.error("Error fetching data:", error);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [videoChooseId]);

  /*
  videoChooseが変更された時に、最初のvideoをセットする
  さらに、historyに最初のvideoを追加する
  */
  useEffect(() => {
    setVideo(videoChoose.videos[videoChoose.videosOrdered[0]]);
    if (videoChoose.videosOrdered[0]) {
      setHistory((prevHistory) => [
        ...prevHistory,
        videoChoose.videosOrdered[0],
      ]);
    }
  }, [videoChoose]);

  /*
  videoに変更があった時に再生を開始する。
  ただし、autoPlayがfalse(初期状態)の時は再生しない
  ProgressBarのアニメーションを再開する
  */
  useEffect(() => {
    if (video && videoRef.current && autoPlay) {
      videoRef.current.play();
      setProgressTransition("0.3s linear");
      if (autoClose) {
        handleScrollToBottom();
      }
    }
  }, [video, autoClose]);

  //iframeで実行された場合
  useEffect(() => {
    const handleReceiveMessage = (event: MessageEvent<MessageData>) => {
      if (
        typeof event.data !== "object" ||
        event.data === null ||
        !event.data.action
      ) {
        return;
      }

      const data = event.data;
      if (data.action === "play") {
        videoRef.current?.play();
        fadeOutButton();
        handleScrollToTop();
      } else if (data.action === "pause") {
        videoRef.current?.pause();
      }

      switch (data.action) {
        case "play":
          videoRef.current?.play();
          fadeOutButton();
          handleScrollToTop();
          break;
        case "pause":
          videoRef.current?.pause();
          break;
        default:
      }
    };
    window.addEventListener("message", handleReceiveMessage);
    return () => {
      window.removeEventListener("message", handleReceiveMessage);
    };
  }, []);

  //再生ボタンを押した後にfadeout
  const fadeOutButton = () => {
    let opacity = 1;
    const fadeOutInterval = setInterval(() => {
      if (opacity <= 0) {
        clearInterval(fadeOutInterval);
      } else {
        opacity -= 0.15;
        setPlayButtonOpacity(opacity);
      }
    }, 1);
  };

  //Clipboardにリンクをコピーする
  const copyLinkToClipboard = async () => {
    const currentPageUrl = window.location.href; // Gets the current page's URL
    try {
      await navigator.clipboard.writeText(currentPageUrl);
      enqueueSnackbar("リンクをコピーしました", {
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
      if (videoChooseId && video?.id) {
      }
    } catch (err) {
      console.error("Failed to copy: ", err); // Handle any errors
      enqueueSnackbar("コピーに失敗しました", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
    }
  };

  const handleFormModalClose = async () => {
    setFormModal(false);
    setSelectedFormId("");
  };

  if (loading) {
    return (
      <div className="content-gradient" style={commonStyles.gradientContainer}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress
            size={32}
            style={{ color: "rgba(255,255,255, 0.8)" }}
          />
        </Box>
      </div>
    );
  }

  return (
    <div className="content-gradient" style={commonStyles.gradientContainer}>
      {/*Progressバー */}
      <Box
        sx={[
          commonStyles.progressBarBox,
          {
            width: isIFrame ? "calc(97% - 45px)" : "97%",
            left: isIFrame ? (mdUp ? "1.5%" : "10px") : "50%",
            transform: isIFrame ? "none" : "translateX(-50%)",
          },
        ]}
      >
        {history.length > 0 &&
          video &&
          history.map((his, index) => {
            return (
              <LinearProgress
                key={his}
                variant="determinate"
                value={
                  his == video?.id
                    ? progress
                    : index >= history.indexOf(video.id)
                    ? 0
                    : 100
                }
                style={{
                  height: 2,
                  backgroundColor: "grey",
                  width: `calc((100% / ${history.length}) - 4px)`,
                }}
                sx={{
                  "& .MuiLinearProgress-barColorPrimary": {
                    backgroundColor: "white",
                    transition: progressTransition,
                  },
                }}
              />
            );
          })}
      </Box>

      {/* iframeで読み込まれている場合は閉じるボタン */}
      {isIFrame && (
        <Box
          sx={{
            position: "absolute",
            top: 15,
            right: 20,
            display: "flex",
            flexDirection: "row",
            zIndex: 500,
            width: 40,
          }}
        >
          <Button
            disableRipple
            sx={{
              width: "100%",
              padding: 0,
              transition: "none",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
            onClick={() => {
              window.parent.postMessage(
                {
                  action: "closeVideoChoose",
                },
                "*"
              );
            }}
          >
            <Iconify
              icon={"mingcute:close-fill"}
              width={20}
              height={20}
              color={"rgba(255,255,255, 1)"}
              sx={{ pointerEvents: "none" }}
            />
          </Button>
        </Box>
      )}

      <Box sx={commonStyles.titleBox}>
        <Typography variant="caption" sx={commonStyles.titleTypography}>
          {video?.video_title}
        </Typography>
      </Box>

      {/*バックボタンとツールバー */}
      <Box>
        {/*左のBackボタン*/}
        {history.length > 1 && history[0] != video?.id && (
          <Box>
            <Button
              onClick={() => {
                setProgressTransition("none");
                setAutoPlay(true);
                if (video) {
                  const currentVideoIndex = history.indexOf(video.id);
                  const prevVideoId = history[currentVideoIndex - 1];
                  if (prevVideoId !== undefined) {
                    const nextVideo = videoChoose.videos[prevVideoId];
                    if (nextVideo) {
                      setProgress(0);
                      setOpacity(1);
                      setVideo(nextVideo);
                      setIsVideoEnd(false);
                      if (prevVideoId !== undefined) {
                        const videoId: string = prevVideoId;
                        setHistory((prevHistory) => {
                          if (!prevHistory.includes(videoId)) {
                            return [...prevHistory, videoId];
                          } else {
                            return prevHistory;
                          }
                        });
                      }
                    }
                  }
                }
              }}
              sx={[
                commonStyles.backButton,
                {
                  left: mdUp ? 25 : 10,
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                },
              ]}
            >
              <Iconify
                icon={"ep:arrow-left"}
                width={12}
                style={{ position: "relative", top: -1 }}
                color={"rgba(255,255,255, 1)"}
              />
              <span style={{ color: "#fff", fontSize: 12 }}>戻る</span>
            </Button>
          </Box>
        )}

        {/*右のツールボタン*/}
        <Box sx={{}}>
          <Button
            onClick={() => setIsMuted(!isMuted)}
            style={{
              position: "absolute",
              top: mdUp ? 75 : 65,
              right: mdUp ? 25 : 10,
              zIndex: 500,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: 0,
              borderRadius: "50%",
              width: 45,
              height: 45,
              minWidth: 45,
              minHeight: 45,
              backgroundColor: "rgba(0,0,0, 0.7)",
              transform: "none",
            }}
          >
            <Iconify
              icon={
                isMuted
                  ? "fluent:speaker-mute-24-regular"
                  : "fluent:speaker-2-16-regular"
              }
              width={28}
              color={"rgba(255,255,255, 1)"}
            />
          </Button>
          <Button
            onClick={copyLinkToClipboard}
            style={{
              position: "absolute",
              top: mdUp ? 130 : 120,
              right: mdUp ? 25 : 10,
              zIndex: 500,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: 0,
              borderRadius: "50%",
              width: 45,
              height: 45,
              minWidth: 45,
              minHeight: 45,
              backgroundColor: "rgba(0,0,0, 0.7)",
              transform: "none",
            }}
          >
            <Iconify
              icon={"tdesign:link"}
              width={24}
              color={"rgba(255,255,255, 1)"}
            />
          </Button>
        </Box>
      </Box>

      {/*中央のボタン */}

      {/*再生*/}
      <Box
        sx={[
          commonStyles.centerButton,
          {
            opacity: playButtonOpacity,
          },
        ]}
      >
        <Button
          aria-label="Play"
          style={{}}
          onClick={() => {
            if (!videoRef.current?.paused) {
              videoRef.current?.pause();
              setPlayButtonOpacity(1);
              handleScrollToTop();
            } else {
              setAutoPlay(true);
              videoRef.current?.play();
              if (autoClose) {
                handleScrollToBottom();
              }
              fadeOutButton();
            }
          }}
        >
          <Iconify
            icon={"mdi:play"}
            width={50}
            color={"rgba(255,255,255, 1)"}
          />
        </Button>
      </Box>

      {/*巻き戻し */}

      {isVideoEnd && (
        <Box sx={commonStyles.centerButton}>
          <Button
            aria-label="RePlay"
            style={{}}
            onClick={() => {
              videoRef.current?.play();
              if (autoClose) {
                handleScrollToBottom();
              }
              setProgress(0);
              setIsVideoEnd(false);
            }}
          >
            <Iconify
              icon={"mdi:replay"}
              width={50}
              color={"rgba(255,255,255, 1)"}
            />
          </Button>
        </Box>
      )}

      {videoLoading && (
        <Box sx={commonStyles.videoLoading}>
          <CircularProgress size={32} style={{ color: "rgba(0, 0, 0, 0.4)" }} />
        </Box>
      )}

      {/*動画 */}
      <Box
        onClick={() => {
          if (videoRef.current) {
            if (!videoRef.current.paused) {
              videoRef.current.pause();
              setPlayButtonOpacity(1);
              handleScrollToTop();
            }
          }
        }}
      >
        <video
          key={video?.video_url}
          playsInline
          ref={videoRef}
          poster={video?.thumbnail}
          style={{
            // height: "100vh",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            width: "100%",
            height: "100%",
            zIndex: 180,
            objectFit: mdUp ? "contain" : "cover",
            objectPosition: "center center",
          }}
          muted={isMuted}
        >
          <source src={video?.video_url} type="video/mp4" />
        </video>
        <img
          key={`thumbnail`}
          style={{
            // height: "100vh",
            width: "100%",
            height: "100%",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 200,
            opacity: opacity,
            objectFit: mdUp ? "contain" : "cover",
          }}
          src={video?.thumbnail}
        ></img>
        <Modal
          open={formModal}
          onClose={handleFormModalClose}
          closeAfterTransition
          disableScrollLock={true}
        >
          <Fade in={formModal} timeout={100}>
            <div
              style={{
                position: "absolute",
                left: "50%",
                transform: "translateX(-50%)",
                width: "100%",
                height: "100%",
                zIndex: 180,
                objectFit: "contain",
                objectPosition: "center center",
              }}
            >
              <Button
                onClick={handleFormModalClose}
                style={{
                  position: "absolute",
                  top: 10,
                  right: 10,
                  zIndex: 1200,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "100px",
                }}
              >
                <Iconify
                  icon={"mingcute:close-fill"}
                  width={24}
                  color={"rgba(255,255,255, 1)"}
                />
              </Button>
              {videoChooseId &&
                forms[selectedFormId] &&
                forms[selectedFormId].fields && (
                  <VideoChooseForm
                    videoChooseId={videoChooseId}
                    formId={selectedFormId}
                    form={forms[selectedFormId]}
                    fields={forms[selectedFormId].fields}
                    videoRef={videoRef}
                    handleFormModalClose={handleFormModalClose}
                  />
                )}
            </div>
          </Fade>
        </Modal>
      </Box>

      <Subtitle
        subtitleUrl={video?.subtitle_url || null}
        currentTime={videoRef.current?.currentTime || 0}
        isOptions={false}
        opacity={buttonOpacity}
      />

      {/* 選択肢トグルボタン */}
      {isShowButton && (
        <Button
          onClick={handleScrollToTop}
          className={"pulse-btn"}
          sx={[
            commonStyles.toggleOptionButton,
            {
              opacity: buttonOpacity,
            },
          ]}
        >
          <Iconify
            width={16}
            icon={"flowbite:angle-top-solid"}
            sx={{ color: "#fff" }}
          />
        </Button>
      )}

      {/* 選択肢と字幕 */}
      <Box ref={boxRef} onScroll={handleScroll} sx={commonStyles.optionsBox}>
        <Subtitle
          subtitleUrl={video?.subtitle_url || null}
          currentTime={videoRef.current?.currentTime || 0}
          isOptions={true}
          opacity={buttonOpacity}
        />
        {video?.nextActions.map((nextAction) => {
          return (
            <Button
              key={nextAction}
              sx={[
                commonStyles.optionButton,
                {
                  backgroundColor:
                    videoChoose.nextActions[nextAction].option_color != "" &&
                    videoChoose.nextActions[nextAction].option_color != null
                      ? videoChoose.nextActions[nextAction].option_color
                      : "rgba(5, 5, 5, 0.5)",
                  "&:hover": {
                    backgroundColor:
                      videoChoose.nextActions[nextAction].option_color != "" &&
                      videoChoose.nextActions[nextAction].option_color != null
                        ? videoChoose.nextActions[nextAction].option_color
                        : "rgba(5, 5, 5, 0.5)",
                  },
                },
              ]}
              onClick={() => {
                if (videoChoose.nextActions[nextAction].type == "video") {
                  const nextVideoId = videoChoose.nextActions[nextAction];
                  if (
                    nextVideoId !== undefined &&
                    nextVideoId.video_id !== undefined
                  ) {
                    const nextVideo = videoChoose.videos[nextVideoId.video_id];
                    if (nextVideo) {
                      setProgressTransition("none");
                      setAutoPlay(true);
                      setProgress(0);
                      setVideoLoading(true);
                      setOpacity(1);
                      setVideo(nextVideo);
                      setIsVideoEnd(false);
                      setPlayButtonOpacity(0);

                      //カスタムアクション用
                      // if (
                      //   videoChoose.nextActions[nextAction].option_text ==
                      // ) {
                      //   sendCustomAction();
                      // }
                      if (nextVideoId.video_id !== undefined) {
                        const videoId: string = nextVideoId.video_id;
                        setHistory((prevHistory) => {
                          if (!prevHistory.includes(videoId)) {
                            return [...prevHistory, videoId];
                          } else {
                            return prevHistory;
                          }
                        });
                      }
                    }
                  }
                } else if (
                  videoChoose.nextActions[nextAction].type == "outer_link"
                ) {
                  if (videoRef.current) {
                    videoRef.current.pause();
                    setPlayButtonOpacity(1);
                  }

                  if (isIFrame) {
                    window.open(
                      videoChoose.nextActions[nextAction].outer_link,
                      "_self"
                    );
                  } else {
                    window.open(
                      videoChoose.nextActions[nextAction].outer_link,
                      "_blank"
                    );
                  }
                } else if (
                  videoChoose.nextActions[nextAction].type == "internal_link"
                ) {
                  if (videoRef.current) {
                    videoRef.current.pause();
                    setPlayButtonOpacity(1);
                  }

                  if (isIFrame) {
                    sendMessage(
                      videoChoose.nextActions[nextAction].anchor,
                      videoChoose.nextActions[nextAction].is_close_anchor_click
                    );
                  } else {
                    if (videoChoose.nextActions[nextAction].other_link) {
                      window.open(
                        videoChoose.nextActions[nextAction].other_link,
                        "_blank"
                      );
                    }
                  }
                } else if (
                  videoChoose.nextActions[nextAction].type == "custom_form"
                ) {
                  if (videoRef.current) {
                    videoRef.current.pause();
                    setPlayButtonOpacity(1);
                  }

                  const nextVideoId = videoChoose.nextActions[nextAction];
                  if (
                    nextVideoId !== undefined &&
                    nextVideoId.form_id !== undefined
                  ) {
                    setFormModal(true);
                    setSelectedFormId(nextVideoId.form_id);
                  }
                }
              }}
            >
              <span
                style={{
                  color: videoChoose.nextActions[nextAction].option_text_color
                    ? videoChoose.nextActions[nextAction].option_text_color
                    : "#fff",
                  textTransform: "none",
                  fontFamily: '"Open Sans", sans-serif',
                }}
              >
                {videoChoose.nextActions[nextAction].option_text}
              </span>
            </Button>
          );
        })}

        <Box sx={commonStyles.footerBox}>
          <Box
            style={{
              fontSize: 12,
              fontFamily: '"Open Sans", sans-serif',
              letterSpacing: "-0.2px",
            }}
          >
            Powered by &nbsp;
          </Box>
          <Button
            onClick={() => {
              if (videoRef.current) {
                videoRef.current.pause();
                setPlayButtonOpacity(1);
              }
              window.open(`${process.env.REACT_APP_CONSUMER_URL}`, "_blank");
            }}
            sx={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              padding: 0,
            }}
          >
            <img
              src={"/assets/footer-logo.png"}
              style={{ width: 125, borderRadius: 1.5 }}
            />
          </Button>
        </Box>
      </Box>
    </div>
  );
}

const commonStyles = {
  gradientContainer: {
    background: "rgba(0,0,0,.75)",
    display: "flex",
    justifyContent: "center",
    // height: "100vh",
    height: "-webkit-fill-available",
    position: "fixed" as "fixed",
    top: 0,
    width: "100%",
    zIndex: 300,
    right: 0,
    bottom: 0,
    left: 0,
  },
  progressBarBox: {
    position: "absolute",
    top: 25,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    zIndex: 500,
  },

  titleBox: {
    width: "97%",
    position: "absolute",
    zIndex: 500,
    top: 35,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },

  titleTypography: {
    color: "white",
    fontWeight: "normal",
    textAlign: "left",
  },
  backButton: {
    position: "absolute",
    top: 75,
    zIndex: 500,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: 0,
  },

  centerButton: {
    position: "absolute",
    top: "calc(37% - 2.5rem)",
    zIndex: 500,
    borderRadius: 54,
    width: 80,
    height: 80,
    backgroundColor: "rgba(0,0,0, 0.7)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transform: "none",
  },

  videoLoading: {
    position: "absolute",
    top: "calc(37% - 2.5rem)",
    zIndex: 500,
    borderRadius: 54,
    width: 80,
    height: 80,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transform: "none",
  },

  toggleOptionButton: {
    position: "absolute",
    transition: "bottom 0.5s ease-in-out",
    bottom: 30,
    zIndex: 500,
    minWidth: "auto",
    borderRadius: "50%",
  },

  optionsBox: {
    position: "absolute",
    bottom: 0,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    width: 570,
    transition: "transform 1s ease-in-out",
    maxHeight: 380,
    paddingLeft: "124px",
    paddingRight: "124px",
    paddingTop: "calc((var(--vh, 1vh) * 60) - 1.5rem)",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
    zIndex: 300,
    maskImage: "linear-gradient(to bottom, #0000, #000 2rem, #000)",
  },
  optionButton: {
    position: "relative",
    cursor: "pointer",
    alignItems: "center",
    justifyContent: "center",
    transition: "all .15s 0s cubic-bezier(.25,.46,.45,.94)",
    willChange: "all",
    borderRadius: "2rem",
    height: "3.2rem",
    padding: "1rem 0",
    fontWeight: 500,
    color: "#fff",
    textDecoration: "none",
    display: "inline-flex",
    backgroundColor: "rgba(5, 5, 5, 0.5)",
    width: "100%",
    marginBottom: "8px",
    fontSize: 16,
    "&:hover": {
      backgroundColor: "rgba(5, 5, 5, 0.5)",
    },
  },

  footerBox: {
    position: "relative",
    cursor: "pointer",
    alignItems: "center",
    justifyContent: "center",
    transition: "all .15s 0s cubic-bezier(.25,.46,.45,.94)",
    borderRadius: "2rem",
    height: "2.6rem",
    padding: "0.6rem 0",
    fontWeight: 500,
    color: "#fff",
    textDecoration: "none",
    display: "inline-flex",
    width: "100%",
    marginBottom: "8px",
    opacity: 0.7,
  },
};
